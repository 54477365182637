
export async function fetchWithTimeout(url: string, options: any) {
    const { timeout = 5000 } = options;
    
    const controller = new AbortController();
    const id = setTimeout(() => {
        controller.abort();
        console.log("timeout");
    }, timeout);
  
    console.log(timeout);
    const response = await fetch(url, {
      ...options,
      signal: controller.signal  
    });
    clearTimeout(id);
  
    return response;
  }