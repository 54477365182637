
import { useEffect, useRef, useState } from "react";

import 'react-autocomplete-input/dist/bundle.css';

import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Search from "../components/Search";
import Searchbar from "../components/Searchbar";

const serverUrl = window.location.origin;

export default function Default(props:any){

    const ref = useRef<any>();
    
    return(<>
        <div id="page-container">
            <div id="content-wrap">
                < Header/>

                <div className="main-content-1">
                    <h1 className="title-default">skillJot</h1>
                
                    <div className="bar">
                        <Searchbar ref={ref}></Searchbar>
                        <a href="#"> </a>
                    </div>

                    <div className="buttons">
                        <button id="search-button" className="button" type="submit" onClick={() => ref.current?.performSearch()}>Search</button>
                    </div>
                </div>
                < Footer time={props.time}/>
            </div>    
        </div>
    </>)
}