import React, { ForwardedRef, forwardRef, MutableRefObject, useEffect, useImperativeHandle, useState } from "react";
import Search from "./Search";
import ReactGA from 'react-ga4';

export interface SearchbarProps {
    value?: string
}

const serverUrl = window.location.origin;

export default forwardRef((props: SearchbarProps, ref: React.ForwardedRef<any>) => {
    const [searchData, setSearchData] = useState<any>({});

    const [searchTypes, setSearchTypes] = useState<any>({
        "contributions": [],
        "project": [],
        "user": null,
    });

    useEffect(function () {
        // Set roles
        if(Object.keys(searchTypes.project).length === 0) {
            var url = serverUrl + "/api/v1/search?type=''" ;

            fetch(url,{
                method:"GET",
                headers: {
                    accept: 'application.json',
                    'Content-Type': 'application/json',
                }
            })
            .then(async response => {
        
                // check for error response
                if (!response.ok ) {
                    // get error message from body or default to response status
                    console.log("Error in response code")
                    const error = await response.text();
                    return Promise.reject(error);
    
                }else{
        
                    const data1 = await response.text();
                    const dataRoles = JSON.parse(data1); 
    
                    //Set projects
                    var url = serverUrl + "/api/v1/search?type=projects" ;
    
                    fetch(url,{
                        method:"GET",
                        headers: {
                            accept: 'application.json',
                            'Content-Type': 'application/json',
                        }
                    })
                    .then(async response => {
                
                        // check for error response
                        if (!response.ok ) {
                            // get error message from body or default to response status
                            console.log("Error in response code")
                            const error = await response.text();
                            return Promise.reject(error);
    
                        }else{
                
                            const data1 = await response.text();
                            const data = JSON.parse(data1);
                            setSearchTypes({...searchTypes, project: data, contributions: dataRoles});
                        } 
                    })
                    .catch(error => {
                        console.error("Error in API Issue: ", error);
                    }); 
                } 
            })
            .catch(error => {
                console.error("Error in API Issue: ", error);
            });    
        }

    },[searchTypes])

    // Function to perform the search action
    function performSearch(params?: any) {
        if(!params) {
            params = searchData
        }

        const urlParams: any = {};
        for(const key in params) {
            switch(key) {
            case "user": 
            case "*":
                if(urlParams["user"]) {
                    urlParams["user"] = [...urlParams["user"], ...params[key]]
                } else {
                    urlParams["user"] = params[key];
                }
                break;
        
            default:
                if(urlParams[key]) {
                    urlParams[key] = [...urlParams[key], ...params[key]]
                } else {
                    urlParams[key] = params[key];
                }
                break;
            }
        }

        // Construct the URL with the query parameter
        const profileURL = `/profile?${Object.keys(urlParams).map((val: string) => {
            return val + "=" + urlParams[val].join(",")
        }).join("&")}`;

    
        // GA4 Btn 
        ReactGA.event({
            action:'jotsearch-submit_action',
            category:'jotsearch-submit_category',
        })
    

        // Navigate to the profile subpage
        window.location.href = profileURL;
    }        

    useImperativeHandle(ref, () => ({
        performSearch: performSearch,
    }))


    return (<>
        {searchTypes.contributions.length > 0 && searchTypes.project.length > 0 && <Search page='default' options={searchTypes} callback={(options, enter) => {
            if(enter) {
                performSearch(options);
            } else {
                setSearchData(options);
            }
        }}></Search>}

    </>)
})