import "../../css/footer.scss";

export default function Footer(props:any){
    return(
        <footer id="footer" >
            <div className="footer-container">
                <span className="footer-text ">This work is licensed under <a rel="license noreferrer" href="https://www.gnu.org/licenses/gpl-3.0.en.html" target="_blank">GPLv3</a></span>
                <span className="footer-text ">
                    Last Updated: {props.time}{/* site.time | date: '%Y-%m-%d' } { site.timezone_short*/}. 
                    Version: 1.2
                </span>
            </div>
        </footer>
    )
}