
import ReactGA from 'react-ga4';

export default function Header(){

    const visitProfile =() => {
        // GA4 Btn 
        ReactGA.event({
            action:'jotprofile-submit_action',
            category:'jotprofile-submit_category',
        })
    }

    const visitSkills = () => {
        // GA4 Btn 
        ReactGA.event({
            action:'jotskills-submit_action',
            category:'jotskills-submit_category',
        })
    }

    return(<>
         <header className="header-default">
            <a href="https://github.com/skilljot/verifiable-profile-template" onClick={() => visitProfile() }>jotProfile</a>
            <a href="https://github.com/skilljot/git-recognise" onClick={() => visitSkills()}>jotSkills</a>
        </header>
    </>)
}