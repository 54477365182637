import { useEffect, useState } from 'react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';

export interface LazyLoaderProps<V> {
    elementFetcher: (offset: string, limit: string) => Promise<{results: V[], is_more_data: boolean}>
    elementFilter: (element: V, value: string) => boolean
    limit?: number
}

export default function useLazyLoaderWSearch<V, R extends HTMLElement>(props: LazyLoaderProps<V>) {

    // set default limit
    const limit = (props.limit ? props.limit : 2);

    const [elements, setElements] = useState<V[]>([]);
    const [filteredElements, setFilteredElements] = useState<V[]>([]);
    const [filterValue, setFilterValue] = useState("");
    const [offset, setOffset] = useState(0);
    const [end, setEnd] = useState(false);
    const [loading, setLoading] = useState(false);

    function getMoreData() {
        if(!end && !loading) {
            setLoading(true);
        }
    }

    function deleteElement(index:number){
        setElements((oldProducts) => {
            oldProducts.splice(index, 1);
            return oldProducts;
        })
    }

    function filterElement(value:string){
        setFilteredElements(elements.filter((product) => props.elementFilter(product, value)));
    }

    const lazyLoadingRef = useBottomScrollListener<R>(getMoreData)

    useEffect(() => {
        if(loading) {
            console.log("fetching", offset, limit);
            props.elementFetcher(offset.toString(), limit.toString()).then((resp) => {
                console.log("fetched", offset, limit, "recieved", resp);
                setElements((old) => {
                    return [...old, ...resp.results]
                })
                if(!resp.is_more_data) {
                    setEnd(true);
                } else {
                    setOffset((old) => {
                        return old + limit;
                    })
                }
            }).catch((e) => {
                console.error(e);
                setEnd(true);
            }).finally(() => {
                setLoading(false);
            })
        }
    }, [loading])

    useEffect(() => {
        if ((lazyLoadingRef.current?.scrollHeight ?? 0) <= (lazyLoadingRef.current?.clientHeight ?? 0)) {
            getMoreData();
        } else if(filteredElements.length === 0) {
            getMoreData();
        }
    }, [filteredElements])

    useEffect(() => {
        //console.log("useEffect on elements or filterValue")
        filterElement(filterValue);
    }, [elements, filterValue])

    return {lazyLoadingRef, elements: filteredElements, end, loading, deleteElement, filterElement: setFilterValue}
}