
import { useState } from "react";
import { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Default from "./DefaultTest";
import Profile from "./Profile";
import ReactGA from "react-ga4"; // Google Analytic

let serverUrl = window.location.origin;
ReactGA.initialize("G-L1NWELSCBG"); 

export default function LandingPage(){

    const [updatedTime, setUpdatedTime] = useState("");

    useEffect(function () {

        var url = serverUrl + "/api/v1/info" ;

        fetch(url,{
            method:"GET",
            headers: {
                accept: 'application.json',
                'Content-Type': 'application/json',
            }
        })
        .then(async response => {
    
            // check for error response
            if (!response.ok ) {
                // get error message from body or default to response status
                console.log("Error in response code")
                const error = await response.text();
                return Promise.reject(error);

            }else{
    
                const data1 = await response.text();
                const data = JSON.parse(data1);
                let time = new Date(data.updated_at * 1000);
                setUpdatedTime(time.toLocaleDateString());
            } 
        })
        .catch(error => {
            console.error("Error in API Issue: ", error);
        }); 

    },[])
    
    return(<>
        <BrowserRouter>
            <div className="App">
                <Routes>

                    {/* Default page */}
                    <Route path="/" element={<Default time={updatedTime} />} />

                    {/* Profile page with results */}
                    <Route path="/profile/" element={<Profile time={updatedTime} />}/> 
                    <Route path="/profile/:id" element={<Profile time={updatedTime} />}/>

                    {/* Not valid path */}
                    <Route path="*" element={<Navigate to="/" />} /> 

                </Routes>
            </div>
        </BrowserRouter> 
    </>)
}