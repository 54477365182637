import { ChangeEvent, FocusEvent, KeyboardEvent, useEffect } from "react"
import { useRef, useState } from "react"

export interface SearchOptions {
    [k: string]: string[] | null
}

export interface SearchProps {
    value?: string

    options: SearchOptions
    page:string

    callback?: (values: any, enter: boolean) => void
}

export default function Search(props: SearchProps){
    const [value, setValue] = useState((props.value ? props.value: ""));
    const [showOptions, setShowOptions] = useState(false);
    const [options] = useState<string[]>(() => {
        let out: string[] = Object.keys(props.options).map((o) => o + ":")
        Object.keys(props.options).forEach((val) => {
            let subOptions = props.options[val];
            if(subOptions && subOptions !== null) {
                out.push(...subOptions.map((s) => val + ":" + s));
            }
        })
        return out
    });
    const [availableOptions, setAvailableOptions] = useState<string[]>(options);

    const [, setLeft] = useState(0);
    const [, setTop] = useState(0);

    const ref = useRef<HTMLInputElement>(null);

    function calcCallback(enter: boolean, updatedValue ?: string) {
        if(props.callback) {
            const words = (updatedValue ? updatedValue.split(" ") : value.split(" "))
            const data: any = {};
            words.forEach((val) => {
                let cache = val.split(":");
                if(cache.length === 2) {
                    if(data[cache[0]]) {
                        data[cache[0]].push(cache[1])
                    } else {
                        data[cache[0]] = [cache[1]]
                    }
                } else {
                    if(data["*"]) {
                        data["*"].push(cache[0])
                    } else {
                        data["*"] = [cache[0]]
                    }
                }
            })

            props.callback(data, enter);
        }
    }

    useEffect(() => {
        if(ref.current) {
            setTop(ref.current.getBoundingClientRect().top + 32)
            setLeft(ref.current.getBoundingClientRect().left);
        }
    }, [ref])

    useEffect(() => {
        const words = value.split(" ")
        const lastWord = words[words.length - 1];
        setAvailableOptions(options.filter((option) => {
            if(lastWord.includes(":")) {
                // selecting from children
                return option.includes(lastWord) && option !== lastWord;
            }
            // selecting from adults
            return option.includes(lastWord) && option[option.length - 1] === ':';
        }).filter((value, index) => {
            return index < 5
        }))
        calcCallback(false);

    }, [value])


    return( 
        <>
            <input 
                autoComplete="off"
                ref={ref}
                id="github-handler" 
                className="searchbar" 
                type="text" 
                title="Search" 
                placeholder="GitHub username or project" 
                value={value}
                onFocus={(event: FocusEvent<HTMLInputElement>) => {
                    event.preventDefault();
                    // user is typing
                    setShowOptions(true);
                }}
                onBlur={(event: FocusEvent<HTMLInputElement>) => {
                    // user is no longer typing
                    if(!event.relatedTarget) {
                        setShowOptions(false);
                    }
                }}
                onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
                    if(event.key === 'Enter') {
                        calcCallback(true);
                    }
                }}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    // user typed something new
                    setValue((event.target.value).toLocaleLowerCase());
                }}/>
            {/*showOptions && availableOptions.length > 0 && <ul tabIndex={0} className="react-autocomplete-input">*/}
            {showOptions && availableOptions.length > 0 && <ul tabIndex={0}  className={ props.page === "default" ? "react-autocomplete-input" : "react-autocomplete-input-profile"} style={{/*left: left + "px", top: top + "px"*/}}>
                {availableOptions.map((option, index) => <li onClick={() => {
                    const words = value.split(" ")
                    words[words.length - 1] = option
                    setValue(words.join(" "));
                    if(ref.current) {
                        ref.current.focus()
                    }

                    if(option !== "user:" && option !== "project:" && option !== "contributions:"){
                        calcCallback(true, option);
                    }

                    console.log("trigger thing");
                }} key={option + index}>{option}</li>)}
            </ul>}
            
        </>
    )
}