import { parse } from 'yaml'

import Footer from "../components/Footer/Footer";
import HeaderProfile from "../components/Header/HeaderProfile";

import RenderJSON from "../components/RenderJSON";

import useLazyLoaderWSearch from "../components/lazyLoadingWSearch";
import { off } from 'process';
import { fetchWithTimeout } from '../components/fetchTimeout';

export default function Profile(props: any) {

    const { lazyLoadingRef, elements, end, loading } = useLazyLoaderWSearch<any, HTMLDivElement>({
        elementFetcher: getProfileData, elementFilter: (product: any, value: string) => {
            return true
        }, limit: 5
    })

    return (<>
        <div id="page-container">
            <div ref={lazyLoadingRef} style={{ overflowY: "scroll", maxHeight: "100vh" }} id="content-wrap">
                < HeaderProfile />

                <div className="main-content-2">
                    <div className="separator"></div>

                    <div className="profile-container" id="profile-container">
                        {/* JSON data will be inserted here */
                            elements.map((val) => {
                                return <div key={val.id + "_" + val.username} >
                                    {reactRenderJSON("", val)}
                                </div>
                            })
                        }

                        {end && elements.length == 0 && <div className='no-results'>
                            <p>Couldn't find the profile you were looking for? No worries!</p>
                            <p>👉 Create your <a href="https://github.com/skillJot/verifiable-profile-template" target="_blank">Verifiable Profile</a> and showcase your verifiable skills and contributions.</p>
                            <p>👉 Recognize your team's skills with <a href="https://github.com/skillJot/git-recognise" target="_blank">Git Recognise.</a></p>
                            <p>👉 Acknowledge your team's contributions with <a href="https://github.com/all-contributors/all-contributors" target="_blank">All Contributors.</a></p>
                            <p>Ready to get started? Click the links above to exhibit your skills and contributions. Happy coding!</p>

                        </div>}

                        {loading && <>Loading ...</>}
                        {end && <></>}
                    </div>
                  
                </div>

                < Footer time={props.time} />
            </div>
        </div>
    </>)
}


function reactRenderJSON(key: string, json: any, name?: string,name_url?: string): JSX.Element {
    const type = typeof json;

    switch (type) {
        case 'object':
            if (json === null) {
                return <RenderJSON key={key} divClassName="json-null">null</RenderJSON>
            } else if (Array.isArray(json)) {
                return <RenderJSON key={key} isCollapsed={"[...]"} divClassName="json-array">
                    <>
                        {json.map((item, index) => {
                            return reactRenderJSON(key + ".array" + index, item)
                        })}
                    </>
                </RenderJSON>
            } else {
                return <RenderJSON key={key} isCollapsed="{...}" divClassName="json-object">
                    <>
                        {Object.keys(json).map((item, index) => {

                            if(item === "username"){
                                return <div key={key + "_div" + item}>
                                    <span key={item + "_span"} className="json-key">{"githubHandler" + ": "}</span>
                                    {reactRenderJSON(key + ".object" + item, json[item], item)}
                                </div>
                            }

                            else if(item !== "id" && item !== "link" ){
                                 return <div key={key + "_div" + item}>
                                    <span key={item + "_span"} className="json-key">{item + ": "}</span>
                                    {reactRenderJSON(key + ".object" + item, json[item], item,json["link"])}
                                </div>
                            }
                           
                        })}
                    </>
                </RenderJSON>
            }
        case 'string':
            if (name === 'project') {
                return <RenderJSON key={key} divClassName="json-string">
                    <a className="clickable" href={json} target="_blank" rel="noreferrer">
                        {json}
                    </a>
                </RenderJSON>
            }else if (name === 'username') {
                return <RenderJSON key={key} divClassName="json-string">
                    <a className="clickable" href={"https://github.com/"+json} target="_blank" rel="noreferrer">
                        {json}
                    </a>
                </RenderJSON>
            }else if (name === 'name') {
                return <RenderJSON key={key} divClassName="json-string">
                    <a className="clickable" href={name_url} target="_blank" rel="noreferrer">
                        {json}
                    </a>
                </RenderJSON>
            }  else if (name === 'Error') {
                return <RenderJSON key={key} divClassName="json-string">
                    <a className="clickable" href="https://github.com/skillJot/verifiable-profile-template" target="_blank" rel="noreferrer">
                        {json}
                    </a>
                </RenderJSON>
            } else {
                return <RenderJSON key={key} divClassName="json-string">
                    {json}
                </RenderJSON>
            }
        case 'number':
            return <RenderJSON key={key} divClassName="json-number">{json}</RenderJSON>
        case 'boolean':
            return <RenderJSON key={key} divClassName="json-boolean">{json ? "true" : "false"}</RenderJSON>
        default:
            if (!json) {
                return <RenderJSON key={key} divClassName="json-null">{"null"}</RenderJSON>
            }
            return <>Should never happen</>
    }
}

async function getProfileData(offset: string, limit: string): Promise<{results: any[], is_more_data: boolean}> {

    const urlParams = new URLSearchParams(window.location.search);

    // Get the value of the 'id' query parameter
    let githubHandler = urlParams.get('id');

    if(githubHandler === null){
        githubHandler = urlParams.get('user');
    }
    let userProfile: any | undefined;

    if (githubHandler !== null && offset === "0") {
        const profileResponse = await fetch(`https://raw.githubusercontent.com/${githubHandler}/verifiable-profile/main/my-profile.yaml`);
        if (profileResponse.ok) {    
            const profileText = await profileResponse.text();
            // Parse the YAML content into a JavaScript object
            const profileData = parse(profileText);

            // Validate the profile data against the JSON schema
            /*
            const profileSchema = {
                // JSON schema for the profile data (as provided in the question)
            };
            */

            // const profileValidator = new Ajv();
            // const isProfileValid = profileValidator.validate(profileSchema, profileData);
            const isProfileValid = true

            if (!isProfileValid) {
                // eslint-disable-next-line no-throw-literal
                throw 'Profile data is not valid according to the schema'
            }

            // Create an object to store the profile data

            userProfile = {
                Name: profileData.givenName + ' ' + profileData.familyName,
                Bio: profileData.bio,
                Location: profileData.address,
                Projects: [],
            }

            // Loop over the projects and fetch contributions.yaml for each project
            for (const projectURL of profileData.projects) {
                // Replace the "github.com" with "raw.githubusercontent.com" in the projectURL
                const rawProjectURL = projectURL.replace('github.com', 'raw.githubusercontent.com');
                // Construct the URL to the contributions.yaml file in the main branch
                const contributionsURL = `${rawProjectURL}/main/contributions.yaml`;
                try {
                    const contributionsResponse = await fetch(contributionsURL);
                    const contributionsText = await contributionsResponse.text();

                    // Parse the YAML content into a JavaScript object
                    const contributionsData = parse(contributionsText);

                    // Validate the contributions data against the JSON schema
                    /*
                    const contributionsSchema = {
                    // JSON schema for the contributions data (as provided in the question)
                    };
                    */

                    // const contributionsValidator = new Ajv();
                    // const isContributionsValid = contributionsValidator.validate(contributionsSchema, contributionsData);
                    const isContributionsValid = true

                    if (!isContributionsValid) {
                        console.error('Contributions data is not valid according to the schema');
                        continue;
                    }

                    // Find the contributor that matches the entered GitHub handler
                    const matchingContributor = contributionsData.contributors.find((contributor: { githubHandler: string | null; }) => {
                        return contributor && contributor.githubHandler === githubHandler
                    });

                    matchingContributor.startDate = matchingContributor.startDate.toLocaleString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: '2-digit'
                    });

                    if (matchingContributor) {
                        userProfile.Projects.push({
                            project: projectURL,
                            contributions: matchingContributor,
                        });
                    }
                } catch (e: any) {
                    //console.log("Caught error in getting contributions:", e);
                    userProfile.Projects.push({
                        project: projectURL,
                        contributions: {
                            githubHandler: githubHandler,
                            skills: "No data found"
                        },
                    });
                }
            }
        }
    } 
    
    if (urlParams.get("project") !== null || urlParams.get("contributions") !== null || urlParams.get("user") !== null) {

        // get filters from database
        const body: any = {
            "projects": urlParams.get("project")?.split(","),
            "roles": urlParams.get("contributions")?.split(","),
            "users": urlParams.get("user")?.split(",")
        }

        return await fetchWithTimeout('/api/v1/search?limit=' + limit + '&offset=' + offset, {
            method: "POST",
            body: JSON.stringify(body),
            timeout: 5000,
        }).then(resp => resp.json()).then((val: {results: any[], is_more_data: boolean}) => {
            if(userProfile) {
                val.results = [userProfile, ...val.results]
            }
            return val
        })
    } else {
        return new Promise((resolve) => {
            resolve({ results: [], is_more_data: false })
        })
    }

}



async function getProfileDataTODO<V>(offset: string, limit: string): Promise<{ results: V[], is_more_data: boolean }> {

    const urlParams = new URLSearchParams(window.location.search);

    // Get the value of the 'id' query parameter
    const githubHandler = urlParams.get('user');
    let userProfile: any = {};

    if (githubHandler !== null && offset === "0") {
        const profileResponse = await fetch(`https://raw.githubusercontent.com/${githubHandler}/verifiable-profile/main/my-profile.yaml`);
        if (!profileResponse.ok) {
            throw new Error(`Failed to fetch profile data. Status: ${profileResponse.status}`);
        }

        const profileText = await profileResponse.text();
        // Parse the YAML content into a JavaScript object
        const profileData = parse(profileText);

        // Validate the profile data against the JSON schema
        /*
        const profileSchema = {
            // JSON schema for the profile data (as provided in the question)
        };
        */

        // const profileValidator = new Ajv();
        // const isProfileValid = profileValidator.validate(profileSchema, profileData);
        const isProfileValid = true

        if (!isProfileValid) {
            // eslint-disable-next-line no-throw-literal
            throw 'Profile data is not valid according to the schema'
        }

        // Create an object to store the profile data

        userProfile = {
            Name: profileData.givenName + ' ' + profileData.familyName,
            Bio: profileData.bio,
            Location: profileData.address,
            Projects: [],
        }

        // Loop over the projects and fetch contributions.yaml for each project
        for (const projectURL of profileData.projects) {
            // Replace the "github.com" with "raw.githubusercontent.com" in the projectURL
            const rawProjectURL = projectURL.replace('github.com', 'raw.githubusercontent.com');
            // Construct the URL to the contributions.yaml file in the main branch
            const contributionsURL = `${rawProjectURL}/main/contributions.yaml`;
            try {
                const contributionsResponse = await fetch(contributionsURL);
                const contributionsText = await contributionsResponse.text();

                // Parse the YAML content into a JavaScript object
                const contributionsData = parse(contributionsText);

                // Validate the contributions data against the JSON schema
                /*
                const contributionsSchema = {
                // JSON schema for the contributions data (as provided in the question)
                };
                */

                // const contributionsValidator = new Ajv();
                // const isContributionsValid = contributionsValidator.validate(contributionsSchema, contributionsData);
                const isContributionsValid = true

                if (!isContributionsValid) {
                    console.error('Contributions data is not valid according to the schema');
                    continue;
                }

                // Find the contributor that matches the entered GitHub handler
                const matchingContributor = contributionsData.contributors.find((contributor: { githubHandler: string | null; }) => {
                    return contributor && contributor.githubHandler === githubHandler
                });

                matchingContributor.startDate = matchingContributor.startDate.toLocaleString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit'
                });

                if (matchingContributor) {
                    userProfile.Projects.push({
                        project: projectURL,
                        contributions: matchingContributor,
                    });
                }
            } catch (e: any) {
                //console.log("Caught error in getting contributions:", e);
                userProfile.Projects.push({
                    project: projectURL,
                    contributions: {
                        githubHandler: githubHandler,
                        skills: "No data found"
                    },
                });
            }

        }

        return {
            is_more_data: false,
            results: [userProfile],
        }
    }

    if (urlParams.get("project") !== null || urlParams.get("contributions") !== null || urlParams.get("user") !== null) {

        // get filters from database
        const body: any = {
            "projects": urlParams.get("project")?.split(","),
            "roles": urlParams.get("contributions")?.split(","),
            "users": urlParams.get("user")?.split(",")
        }


        return await fetch('/api/v1/search?limit=' + limit + '&offset=' + offset, {
            method: "POST",
            body: JSON.stringify(body)
        }).then(resp => resp.json())
    } else {
        return new Promise((resolve) => {
            resolve({ results: [], is_more_data: false })
        })
    }

}