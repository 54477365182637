import { useEffect } from "react";
import { useState } from "react";
import Searchbar from "../Searchbar";
import ReactGA from 'react-ga4';

export default function HeaderProfile(){

    const [value] = useState<string | undefined>();

    useEffect(() => {
        
        /*
        const urlParams = new URLSearchParams(window.location.search);

        let values = ""
        urlParams.forEach((val, key) => {
            if(key === "id") {
                values += val + " "
            } else {
                values += key + ":" + val + " "
            }
        })
        setValue(values);*/
    }, [])

    const visitProfile =() => {
        // GA4 Btn 
        ReactGA.event({
            action:'jotprofile-submit_action',
            category:'jotprofile-submit_category',
        })
    }

    const visitSkills = () => {
        // GA4 Btn 
        ReactGA.event({
            action:'jotskills-submit_action',
            category:'jotskills-submit_category',
        })
    }

    return(<>
        <div className="header">

            {/* Left side of the header */}
            <div className="header-info">
                <a id="header-title" style={{textDecoration: "none"}} href="/">
                    <h1>skillJot</h1>
                </a>
                <div className="bar">
                    <Searchbar></Searchbar>
                    <a href="#"></a>
                </div>
            </div>

            {/*Right side of the header */}
            <div className="header-links">
                <a href="https://github.com/skilljot/verifiable-profile-template"  onClick={() => visitProfile() }>jotProfile</a>
                <a href="https://github.com/skilljot/git-recognise"  onClick={() => visitSkills() }>jotSkills</a>
            </div>
        </div>
    </>)
}