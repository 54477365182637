import { useState } from "react";

export interface RenderJSONProps {
    divClassName: string,
    isCollapsed?: string,
    children?: React.ReactElement[] | string | React.ReactElement
}

export default function RenderJSON(props: RenderJSONProps) {
    const [show, setShow] = useState(true);

    return <div className={show ? props.divClassName : props.divClassName + " json-collapsed"}>
        {props.isCollapsed && <span  onClick={() => {
        setShow(!show);
    }}>
            {props.isCollapsed}
        </span>}
        {show && props.children}
    </div>
}